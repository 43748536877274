<template>
  <section id="features-section" class="features-section py-16 bg-gradient-to-b from-[#e9f5ff] to-white">
    <div class="container mx-auto text-center px-4">
      <h1 class="text-5xl font-bold text-center text-dark-text mb-12">Get your team into the Flow</h1>

      <!-- Dashboard Row -->
      <div class="dashboard-row flex flex-col items-center mb-10 py-10">
        <div class="dashboard-image max-w-full rounded-lg mb-10">
          <img class="scalable dashboard-img" src="@/assets/dashboard_flow.png" alt="Flow Dashboard">
        </div>
        <div class="dashboard-content text-center max-w-3xl">
          <div class="flex flex-col items-center">
            <h3 class="text-3xl font-bold mb-4">Everything you need in one place</h3>
            <p class="text-body-text">Flow brings together all of the features your team needs to safely collaborate with sensitive data. They can organize their own vault by creating or uploading files, access secure groups to work with teams they belong to, and launch apps they have been given access to.</p>
          </div>
        </div>
      </div>

      <div v-for="(feature, index) in features" :key="index" class="feature-row flex flex-col md:flex-row items-center mb-10 md:mb-16">
        <div :class="['feature-image', 'flex-1', 'p-5', { 'md:order-last': index % 2 !== 0 }]">
          <img :src="require(`@/assets/${feature.image}`)" :alt="feature.title" class="scalable object-contain w-full max-w-xl mx-auto rounded-lg transition-transform duration-500 ease-in-out drop-shadow-lg">
        </div>
        <div :class="['feature-content', 'flex-1', 'p-5', 'text-left', { 'md:order-first': index % 2 !== 0 }]">
          <h3 class="text-2xl font-semibold mb-2">{{ feature.title }}</h3>
          <p class="text-body-text" v-html="feature.description"></p>
        </div>
      </div>

      <div class="flex justify-center space-x-4 mt-12">
        <button class="bg-btn-bg text-white py-2 px-6 rounded-lg font-bold text-lg uppercase transition-transform transform hover:scale-105 hover:bg-btn-bg-hover" @click="goToGettingStarted">Get Started</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection',
  data() {
    return {
      features: [
        { 
          title: 'Game of Realms', 
          description: 'Every company gets a realm on Flow. It’s your own private domain to safely work with your team and guests while handling and managing all of your sensitive assets. You can establish trusted realms with your business partners, creating a safer way to share and collaborate while reducing everyone’s attack surface.', 
          image: 'feature_realms.png' 
        },
        { 
          title: 'Create Safe Spaces to Work', 
          description: 'Create spaces to work using Flow groups, and organize people into the teams tailored to your business needs. Assign single sign-on (SSO) apps to groups to narrow access, and your teams can share and collaborate on docs, credentials, and notes.', 
          image: 'feature_spaces.png' 
        },
        { 
          title: 'Manage Access Rights', 
          description: 'Flow groups are secured by privileged access (PAM), making it easy to collaborate with your team and guests in safe spaces with granular rights. Make public access groups for your team to request to join and easily manage their access requests.', 
          image: 'feature_access.png' 
        },
        { 
          title: 'Collaborate on Documents', 
          description: 'Your team, and their guests, can work on DOCX native documents together without ever leaving Flow. Centralize contracts, agreements, legal, HR, and many other sensitive documents in one place, ensuring copies do not proliferate in email and user accounts.', 
          image: 'feature_documents.png' 
        },
        { 
          title: 'Share Inside', 
          description: 'Share with trusted people and groups inside of your company while preserving end-to-end encryption, and easily revoke access at any time.', 
          image: 'feature_share_inside.png' 
        },
        { 
          title: 'Share Outside', 
          description: 'Share with people outside of your company using encrypted links to secure your secrets instead of sending sensitive data through email or text.', 
          image: 'feature_share_outside.png' 
        },
        { 
          title: 'Encrypt All Data', 
          description: 'Every note, file, document, credential, is encrypted from end-to-end and stored in each users’ personal vault, and can only be decrypted by the right identities.', 
          image: 'feature_encrypt.png' 
        },
        { 
          title: 'Protect Apps', 
          description: 'Make sure only the right people in your organization have access to the apps you use, such as single sign-on (SSO) and password-based apps. Apps can be assigned to individuals, your entire company, or to groups, and safely launched using the Launchpad to ensure addresses are authentic and accurate.', 
          image: 'feature_apps.png' 
        },
        { 
          title: 'Manage Passwords', 
          description: 'Import your password credentials from popular password managers like 1password and LastPass, or others, using our import tool. Share credentials with people or with groups while preserving end-to-end encryption. Install our browser password manager plugin on Chrome and Safari to access your credentials while browsing the web.', 
          image: 'feature_passwords.png' 
        },
        { 
          title: 'Mind the (share) Gap', 
          description: 'Already on Azure? Your team can login with your identity provider and interact with those outside of your environment in a \'share gapped\' safe space.', 
          image: 'feature_gap.png' 
        },
        { 
          title: 'Prove Identities', 
          description: 'Flow comes with a companion authentication app for <a href="https://apps.apple.com/ua/app/tozid-authenticator/id1498498814" target="_blank">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.tozny.tozidauthenticator.app&hl=en" target="_blank">Android</a>, TOZ Auth, to verify access requests from your team and your guests. You can also add our biometric TOZ ID Card, or other FIDO compliant hardware keys to step-up multiple factors of identity verification.', 
          image: 'feature_identities.png' 
        },
      ],
    };
  },
  mounted() {
    this.addIntersectionObserver();
  },
  methods: {
    addIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };
      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      };
      const observer = new IntersectionObserver(callback, options);
      const targets = document.querySelectorAll('.scalable');
      targets.forEach((target) => {
        observer.observe(target);
      });
    },
    goToGettingStarted() {
      this.$router.push({ name: 'GettingStarted' });
    },
  },
};
</script>

<style scoped>
.scalable {
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
  image-rendering: crisp-edges; /* For better clarity */
  width: 100%;
  height: auto;
}

.scalable.visible {
  transform: scale(1.02);
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.2));
}

.dashboard-image {
  width: 100%;
  transition: filter 0.5s ease-in-out;
}

.dashboard-image .scalable:hover {
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.2));
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}
</style>